@value colors: "../../styles/global.module.css";
@value darkPurple from colors;

.select {
  appearance: none;
  background-color: white;
  border: 1px solid darkPurple;
  border-radius: 6px;
  color: darkPurple;
  font-size: 18px;
  line-height: 36px;
  padding: 12px 54px 12px 12px;
  width: 100%;
  cursor: pointer;
}

.select input :disabled {
  pointer-events: none;
}

