
.search-container {
  display:flex;
  flex:1 1 0;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.searchbox {
  margin-right:1em;
  flex:2 1 0;
}

.searchbox-plain {
  flex:2 1 0;
}

.search-button {
  flex:1 1 0;
}

/* It would be good to refactor the media query in a more reusable way */
@media screen and (max-width: 30em) {
  .searchbox,
  .searchbox-plain,
  .search-button {
    margin-right:0;
    margin-bottom: 12px;
    flex: 1 1 100%;
    width: 100%;
  }
}


.autocomplete {
  position: relative;
}

.autocomplete-item {
  padding:0.25em;
  font-weight:normal;
  font-size:1rem;
}

.autocomplete-item--truncated {
  max-width: 42rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media (max-width: 737px) {
  .autocomplete-item--truncated {
    max-width: 80vw;
  }
}

.react-autosuggest__suggestions-container {
  background:#fff;
  left: 0;
  right: 0;
  max-height:20em;
  overflow-y: auto;
  position: absolute;
  z-index: 100;
}

.react-autosuggest__suggestions-list {
  border: 1px solid #3e0f48;
  padding:0.25em 0.5em;
  list-style-type: none;
  color:black;
}

