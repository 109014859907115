
.text-input {
  flex: 1;
  border: 1px solid #000000;
  border-radius: 6px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
  padding: 6px 12px;
}

.text-input :disabled {
  pointer-events: none;
}

.upload-icon-container {
  position: relative;
}

.logo-container {
  position: relative;
  display: inline-block;
  max-height: 250px;
}

.logo-container:hover img {
  transition: .5s ease;
  opacity: 0.3;
}

.logo-container:hover .logo-upload-icon {
  opacity: 0.8;
  transition: 1s ease;
}

.logo-upload-icon {
  width: 96px;
  opacity: 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.logo-preview {
  max-height: 250px;
  max-width: 100%;
}

.logo-success-container-hidden {
  opacity: 0;
  transition: all 500ms linear 2s;
}

.logo-success-container-shown {
  opacity: 1;
  transition: all 500ms linear;
}

.centered {
  text-align: center;
}

.black {
  color: #000000
}

.full-width {
  width: 100%;
}
