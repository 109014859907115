@import "../../styles/global.module.css";
@value colors: "../../styles/global.module.css";
@value darkPurple from colors;


.formField {
  padding: 12px;
  background-color: white;
  border: 1px solid darkPurple;
  border-radius: 6px;
  font-size: 18px;
  line-height: 36px;
  width: 100%;
}

.formField *:disabled {
  pointer-events: none;
}
