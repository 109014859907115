.pagination-link {
  color: #3E0F47 !important;
  border: 1px solid #3E0F47;
  font-size: 18px;
  line-height: 24px;
}

.pagination-link:hover {
  border: 1px solid #3E0F47;
}

.pagination-link.is-current {
  background: #FFB000;
  border: 1px solid #FFB000;
}
